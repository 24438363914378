<template>
    <div class='page-box'>
        <div class='container'>pages 111111</div>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'

export default {
    // import引入的组件需要注入到对象中才能使用
    components: {},
    data() {
        // 这里存放数据
        return {

        }
    },
    created() {

    },
    // 生命周期 - 挂载完成(可以访问DOM元素)
    mounted() {

    },
    // 方法集合
    methods: {

    }
}
</script>

<style lang='less' scoped>
.page-box {}
</style>
